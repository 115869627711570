import { Link } from '@missionlane/compass-ui';
import singleHand from '@core/assets/singleHand.svg';
import greenTracks from '@core/assets/greenTracks.svg';
import {
  CLOSE_BUTTON_WIDTH,
  OpportunityTileCloseIcon,
  OpportunityTileContainer,
  OpportunityTileCTA,
} from '../AccountSummaryPage/Dashboard/OpportunityTileCarousel/OpportunityTiles/LayoutComponents';
import { CarouselTileProps } from '../Carousel/Carousel';
import { useNavigate } from 'react-router-dom';

export const REFERRALS_TILE_EVENT = 'Opportunity Tile: Referrals';

const IMAGE_WIDTH = 80;
const IMAGE_RIGHT = 6;
const CONTENT_SAFE_MARGIN = IMAGE_WIDTH - CLOSE_BUTTON_WIDTH - IMAGE_RIGHT;
const ReferralsTile = ({ onDismiss }: CarouselTileProps) => {
  const navigate = useNavigate();
  return (
    <OpportunityTileContainer backgroundColor="pine">
      <OpportunityTileCTA
        trackingName={REFERRALS_TILE_EVENT}
        contentSafeMargin={CONTENT_SAFE_MARGIN}
        title={'Share the Lane'}
        text={
          'Refer your friends and family to the Mission Lane Visa® credit card. '
        }
        onButtonClick={
          //TODO EEC-56: Referrals Page
          () => navigate('../referrals')
        }
        buttonLabel="Start Referring"
        theme="dark"
      />
      <img src={singleHand} className="absolute bottom-0 right-0" />
      <img src={greenTracks} className="absolute top-0 right-0" />

      <Link
        onPress={() => onDismiss(REFERRALS_TILE_EVENT)}
        style={{
          zIndex: 2,
        }}
      >
        <OpportunityTileCloseIcon />
      </Link>
    </OpportunityTileContainer>
  );
};

export default ReferralsTile;
