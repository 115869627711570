import calendarSVG from '@core/assets/dollarSignCalendar.svg';
import handCardSVG from '@core/assets/cardInHand.svg';
import {
  B,
  Button,
  Colors,
  H4,
  Icon,
  P3,
  Spacing,
} from '@missionlane/compass-ui';
import { useTracking } from '@core/services/TrackService/useTracking';
import { FEATURE_NAME } from './const';

export enum TileSVG {
  'calendar',
  'cardInHand',
}

interface BaseOnboardingTileProps {
  body: string;
  title: string;
  buttonTestId?: string;
  testId?: string;
  stepNumber?: string;
  tileSVG?: TileSVG;
  isCompleted?: boolean;
  onDismiss?: () => void;
  hasLargeTitle?: boolean;
}

interface ClickableOnboardingTileProps extends BaseOnboardingTileProps {
  onClick: () => void;
  buttonLabel: string;
  disabled?: boolean;
}

interface UnClickableOnboardingTileProps extends BaseOnboardingTileProps {
  onClick?: never;
  buttonLabel?: never;
  disabled?: never;
}

type OnboardingTileProps =
  | ClickableOnboardingTileProps
  | UnClickableOnboardingTileProps;

const TileSVGComponent = ({
  tileSVG,
}: Pick<BaseOnboardingTileProps, 'tileSVG'>) => {
  switch (tileSVG) {
    case TileSVG.calendar:
      return (
        <div className="self-end">
          <img src={calendarSVG} />
        </div>
      );
    case TileSVG.cardInHand:
      return (
        <div className="self-end">
          <img src={handCardSVG} />
        </div>
      );
    default:
      return null;
  }
};

const MyProgressOnboardingStepTile = ({
  testId,
  body,
  title,
  tileSVG,
  stepNumber,
  onClick,
  buttonLabel,
  buttonTestId,
  isCompleted,
  hasLargeTitle = true,
  onDismiss,
  disabled,
}: OnboardingTileProps) => {
  const { trackClick } = useTracking();

  const handleOnClick = () => {
    onClick?.();
    trackClick({
      name: `${FEATURE_NAME.MY_PROGRESS_ONBOARDING}: ${title}`,
      feature_name: FEATURE_NAME.MY_PROGRESS_ONBOARDING,
    });
  };

  const handleOnDismiss = () => {
    onDismiss?.();
    trackClick({
      name: `${FEATURE_NAME.MY_PROGRESS_ONBOARDING}: ${title} - Dismiss`,
      feature_name: FEATURE_NAME.MY_PROGRESS_ONBOARDING,
    });
  };

  return (
    <div
      className="flex w-100"
      data-testid={testId}
      style={{ position: 'relative', marginBottom: Spacing.s }}
    >
      <div
        className="flex flex-row w-100"
        style={{
          borderRadius: Spacing.s,
          background: Colors.hazeLightest,
        }}
      >
        <div
          className="flex justify-between items-center w-100"
          style={styles.tileSpacing}
        >
          {isCompleted ?
            <div className="flex flex-row w-100 items-center">
              <Icon
                color="green"
                size="m"
                name="checkCircle"
                style={styles.icon}
              />
              <P3 style={styles.noVerticalMargin} color="green">
                <B>{title}</B>
              </P3>
            </div>
          : <>
              <div className="flex flex-row">
                {stepNumber && <H4>{stepNumber}.</H4>}
                <div style={styles.titleAndBody}>
                  {hasLargeTitle ?
                    <H4>{title}</H4>
                  : <div>
                      <P3 color="ink">
                        <B>{title}</B>
                      </P3>
                    </div>
                  }

                  <P3 style={styles.noVerticalMargin}>{body}</P3>
                </div>
              </div>
              {onClick && (
                <div style={{ marginLeft: 16, maxWidth: 154, width: '100%' }}>
                  <Button
                    testID={buttonTestId}
                    text={buttonLabel}
                    onPress={handleOnClick}
                    disabled={disabled}
                  />
                </div>
              )}
            </>
          }
        </div>
        {!isCompleted && <TileSVGComponent tileSVG={tileSVG} />}
      </div>

      {onDismiss && (
        <div
          className="pointer flex shadow-2"
          style={{
            position: 'absolute',
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: Spacing.xm,
            width: 25,
            height: 25,
            top: -Spacing.s,
            right: -Spacing.s,
            backgroundColor: Colors.white,
          }}
          onClick={handleOnDismiss}
        >
          <Icon name="close" color="ink" />
        </div>
      )}
    </div>
  );
};

export default MyProgressOnboardingStepTile;

const styles = {
  tileContainer: {
    flexDirection: 'row',
    borderRadius: Spacing.s,
    width: '100%',
  },
  icon: { marginRight: Spacing.s },
  container: {
    marginBottom: Spacing.s,
    maxWidth: 550,
  },
  svg: {
    alignSelf: 'flex-start',
  },
  h2: {
    fontSize: 24,
  },
  smallHeader: {
    fontSize: Spacing.m,
  },
  titleAndBody: {
    marginRight: Spacing.xs,
    marginLeft: Spacing.xs,
  },
  noVerticalMargin: { marginVertical: 0 },
  tileSpacing: { padding: Spacing.m },
};
