import { B, LoadingIndicator } from '@missionlane/compass-ui';
import { useNavigate } from 'react-router-dom';
import { MenuItem } from './MenuItem';
import { MenuLabel } from './MenuLabel';
import { useAccount } from '../Auth/AccountContext';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { gql } from '@apollo/client';
import { useAccountIdQuery } from '@core/utils/hooks/useAccountIdQuery';
import { AccountSectionQuery } from '@core/graphql/globalTypes';

interface Props {
  cardDetails?: {
    name: string | null;
    last4: string | null;
  };
}

export const AccountSection = ({ cardDetails }: Props) => {
  const navigate = useNavigate();
  const { showManageCard, manageFundingAccounts, enableMyProgressOnboarding } =
    useFlags();
  const { accountId } = useAccount();

  const ACCOUNT_SECTION_QUERY = gql`
    query AccountSection($accountId: String!) {
      account(accountId: $accountId) {
        id
        statuses {
          isBankrupt
        }
      }
    }
  `;

  const { loading, data } = useAccountIdQuery<AccountSectionQuery>(
    ACCOUNT_SECTION_QUERY,
  );

  const isBankRupt = data?.account?.statuses.isBankrupt;

  const getFundingAccountItem = () => {
    if (isBankRupt) {
      return null;
    }

    return manageFundingAccounts ?
        <MenuItem
          trackingName="Profile drop down: Manage bank accounts"
          onSelect={() =>
            navigate(`/account/${accountId}/manage-bank-accounts`)
          }
          icon="bank"
        >
          Manage bank accounts
        </MenuItem>
      : <MenuItem
          trackingName="Profile drop down: Add bank account"
          onSelect={() => navigate(`/account/${accountId}/add-bank-account`)}
          icon="bank"
        >
          Add bank account
        </MenuItem>;
  };

  if (loading) {
    return <LoadingIndicator />;
  }

  const { name: cardName, last4 } = cardDetails || {};
  return (
    <>
      <MenuLabel
        label={
          cardName ?
            <>
              {cardName} <B color="green">(...{last4})</B>
            </>
          : 'Account'
        }
      />
      <MenuItem
        trackingName="Profile drop down: Account details"
        onSelect={() => navigate(`/account/${accountId}/details`)}
        icon="creditCard"
      >
        Account details
      </MenuItem>
      {getFundingAccountItem()}
      {showManageCard && !isBankRupt && (
        <MenuItem
          trackingName="Profile drop down: Manage card"
          onSelect={() => navigate(`/account/${accountId}/manage-card`)}
          icon="gear"
        >
          Manage card
        </MenuItem>
      )}
      {enableMyProgressOnboarding && (
        <MenuItem
          trackingName="Profile drop down: My progress"
          onSelect={() => navigate(`/account/${accountId}/my-progress`)}
          icon="chart"
        >
          My progress
        </MenuItem>
      )}
    </>
  );
};
