import { useAccountPageTitle } from '../Account/useAccountPageTitle';
import CreditScore from '../CreditScore/CreditScore';
import PageWrapper from '../Page/PageWrapper';
import { GoodStandingTips } from '@clip/GoodStandingTips/features';
import { SpendBonus } from '@clip/SpendBonus';
import ClipOffer from '@clip/ClipProgress/kards/ClipOffer/ClipOffer';
import CreditLimitKard from './CreditLimitKard';
import { MLFlags } from 'flags';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { FEATURE_NAME } from '../MyProgressOnboarding/const';

const PAGE_NAME = 'My Progress';

const MyProgressPage = () => {
  const { showCreditScore } = useFlags<MLFlags>();

  return (
    <div className="flex w-100 flex-row-l flex-column">
      {/** left side */}
      <div className="w-50-l mr2-l">
        <CreditLimitKard />
        <ClipOffer />
        <SpendBonus />
      </div>

      {/** right side */}
      <div className="w-50-l ml2-l">
        {showCreditScore && <CreditScore className="mb3" />}
        <GoodStandingTips />
      </div>
    </div>
  );
};

const MyProgressPageWithPageWrapper = () => {
  // TODO: remove with the introduction of sideNave
  const pageTitle = useAccountPageTitle(PAGE_NAME);
  return (
    <PageWrapper
      trackingProperties={{
        featureName: FEATURE_NAME.MY_PROGRESS_ONBOARDING,
        pageName: `${FEATURE_NAME.MY_PROGRESS_ONBOARDING}: My Progress`,
      }}
      pageTitle={pageTitle}
    >
      <MyProgressPage />
    </PageWrapper>
  );
};

export default MyProgressPageWithPageWrapper;
