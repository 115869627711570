import { useEffect, useState } from 'react';
import { TRY_AUTOPAY_TILE_EVENT } from './OpportunityTiles/AutopayTile';
import dayjs from 'dayjs';
import { TrackService } from '@core/services';
import { gql } from '@apollo/client';
import { useAccountIdQuery } from '@core/utils/hooks/useAccountIdQuery';
import {
  OpportunityTileCarouselQueryVariables,
  OpportunityTileCarouselQuery,
} from '@core/graphql/globalTypes';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { MLFlags } from 'flags';
import { PROACTIVE_MULTICARD_TILE_EVENT } from '@clip/Multicard/ProactiveMulticardTile';
import { REACTIVE_MULTICARD_OFFER_EVENT } from '@clip/Multicard/ReactiveMulticardOffer';
import { ADD_PAYMENT_METHOD_TILE_EVENT } from './OpportunityTiles/AddPaymentMethodTile';
import { usePaymentStatus } from '@payments/hooks/usePaymentStatus';
import { OnDismiss } from '@core/components/Carousel/Carousel';
import { ONBOARDING_TILE_EVENT } from '@core/components/Onboarding/OnboardingOpportunityTile';
import { CREDIT_PROTECTION_TILE_EVENT } from '@core/components/CreditProtection/CreditProtectionTile';
import { successStorageKey } from '@core/components/MyProgressOnboarding/MyProgressOnboardingPage';
import { REFERRALS_TILE_EVENT } from '@core/components/Referrals/ReferralsTile';

export const OPPORTUNITY_TILE_CAROUSEL_QUERY = gql`
  query OpportunityTileCarousel($accountId: String!) {
    fundingAccounts {
      id
    }
    proactiveOffers {
      eligible
    }
    reactiveMulticardEligibility(accountId: $accountId) {
      eligible
    }
    account(accountId: $accountId) {
      isEligibleForActivation
      creditProtectionInfo {
        isEligibleForMarketing
      }
      statuses {
        isBankrupt
        isClosed
      }
    }
    onboardingProgress(accountId: $accountId) {
      accountId
      hasMadePayment
      hasUsedCard
      isCardActivated
      isFundingAccountAdded
      isOlderThan90Days
    }
  }
`;

export type OpportunityTileType =
  | typeof TRY_AUTOPAY_TILE_EVENT
  | typeof PROACTIVE_MULTICARD_TILE_EVENT
  | typeof REACTIVE_MULTICARD_OFFER_EVENT
  | typeof ADD_PAYMENT_METHOD_TILE_EVENT
  | typeof CREDIT_PROTECTION_TILE_EVENT
  | typeof ONBOARDING_TILE_EVENT
  | typeof REFERRALS_TILE_EVENT;

export interface OpportunityTile {
  loading: boolean;
  tiles: OpportunityTileType[];
  dismissTile: OnDismiss;
}

export const useOpportunityTileManager = (): OpportunityTile => {
  const {
    addFundingAccountOpportunityTile,
    showOpportunityTilesV2,
    proactiveMulticard,
    clipMulticardOffer,
    creditProtectionMarketing,
    enableMyProgressOnboardingTile,
    showReferrals,
  } = useFlags<MLFlags>();

  const {
    loading: paymentStatusLoading,
    isBankrupt,
    canEnrollInAutopay,
    hasAutopay,
  } = usePaymentStatus();

  const [tiles, setTiles] = useState<OpportunityTileType[]>([]);

  const { data, loading } = useAccountIdQuery<
    OpportunityTileCarouselQuery,
    OpportunityTileCarouselQueryVariables
  >(OPPORTUNITY_TILE_CAROUSEL_QUERY);

  const { statuses } = data?.account || {};

  const {
    hasMadePayment,
    hasUsedCard,
    isCardActivated,
    isFundingAccountAdded,
    isOlderThan90Days,
  } = data?.onboardingProgress || {};

  //Local Storage conditions
  const storedAutopayDismissed = localStorage.getItem(TRY_AUTOPAY_TILE_EVENT);
  const proactiveMulticardDismissed = localStorage.getItem(
    PROACTIVE_MULTICARD_TILE_EVENT,
  );
  const reactiveMulticardDismissed = localStorage.getItem(
    REACTIVE_MULTICARD_OFFER_EVENT,
  );
  const addPaymentMethodDismissed = localStorage.getItem(
    ADD_PAYMENT_METHOD_TILE_EVENT,
  );
  const creditProtectionDismissed = localStorage.getItem(
    CREDIT_PROTECTION_TILE_EVENT,
  );

  const hasSeenSuccessOverlay = localStorage.getItem(successStorageKey);
  const referralsDismissed = localStorage.getItem(REFERRALS_TILE_EVENT);

  //Account conditions
  const proactiveMulticardEligible = data?.proactiveOffers?.eligible;
  const reactiveMulticardEligible =
    data?.reactiveMulticardEligibility?.eligible;
  const hasNoPaymentMethod = !data?.fundingAccounts?.length;

  const isAllStepsCompleted =
    (isCardActivated &&
      hasUsedCard &&
      isFundingAccountAdded &&
      hasMadePayment &&
      hasSeenSuccessOverlay) ||
    isOlderThan90Days ||
    statuses?.isBankrupt ||
    statuses?.isClosed;

  const autopayEligible = canEnrollInAutopay && !hasAutopay && !isBankrupt;
  const isEligibleForCreditProtectionMarketing =
    data?.account?.creditProtectionInfo?.isEligibleForMarketing;

  //Display conditions
  const showProactiveMultiCardTile =
    proactiveMulticard &&
    proactiveMulticardEligible &&
    !proactiveMulticardDismissed;

  const showReactiveMulticardTile =
    clipMulticardOffer &&
    reactiveMulticardEligible &&
    !reactiveMulticardDismissed;

  const showAddPaymentMethodTile =
    showOpportunityTilesV2 &&
    addFundingAccountOpportunityTile &&
    hasNoPaymentMethod &&
    !addPaymentMethodDismissed &&
    !isBankrupt;

  const showAutopayTile =
    showOpportunityTilesV2 && autopayEligible && !storedAutopayDismissed;

  const showCreditProtectionTile =
    showOpportunityTilesV2 &&
    creditProtectionMarketing !== 'DISABLED' &&
    isEligibleForCreditProtectionMarketing &&
    !creditProtectionDismissed;

  const showOnboardingProgressTile =
    enableMyProgressOnboardingTile &&
    showOpportunityTilesV2 &&
    !isAllStepsCompleted;

  const showReferralsTile = showReferrals && !referralsDismissed;

  const dismissTile: OnDismiss = (tileID, options = {}) => {
    //Skip tracking event for data determined removal
    if (!options.skipLocalStorageUpdate) {
      TrackService.trackClick(`Dismiss ${tileID} tile`, {
        ...options.trackingProperties,
        feature: tileID,
      });
      localStorage.setItem(tileID, dayjs().toString());
    }

    setTiles((prev) => prev.filter((tile) => tile !== tileID));
  };

  useEffect(() => {
    if (!loading) {
      if (
        showCreditProtectionTile &&
        !tiles.includes(CREDIT_PROTECTION_TILE_EVENT)
      ) {
        setTiles((prev) => [...prev, CREDIT_PROTECTION_TILE_EVENT]);
      }
      if (
        showOnboardingProgressTile &&
        !tiles.includes(ONBOARDING_TILE_EVENT)
      ) {
        setTiles((prev) => [...prev, ONBOARDING_TILE_EVENT]);
      }
      if (
        showProactiveMultiCardTile &&
        !tiles.includes(PROACTIVE_MULTICARD_TILE_EVENT)
      ) {
        setTiles((prev) => [...prev, PROACTIVE_MULTICARD_TILE_EVENT]);
      }
      if (
        showReactiveMulticardTile &&
        !tiles.includes(REACTIVE_MULTICARD_OFFER_EVENT)
      ) {
        setTiles((prev) => [...prev, REACTIVE_MULTICARD_OFFER_EVENT]);
      }
      if (
        showAddPaymentMethodTile &&
        !tiles.includes(ADD_PAYMENT_METHOD_TILE_EVENT)
      ) {
        setTiles((prev) => [...prev, ADD_PAYMENT_METHOD_TILE_EVENT]);
      }
      if (
        !paymentStatusLoading &&
        showAutopayTile &&
        !tiles.includes(TRY_AUTOPAY_TILE_EVENT)
      ) {
        setTiles((prev) => [...prev, TRY_AUTOPAY_TILE_EVENT]);
      }

      if (showReferralsTile && !tiles.includes(REFERRALS_TILE_EVENT)) {
        setTiles((prev) => [...prev, REFERRALS_TILE_EVENT]);
      }
    }
  }, [loading, paymentStatusLoading]);

  return {
    loading: loading || paymentStatusLoading,
    tiles: tiles,
    dismissTile: dismissTile,
  };
};
