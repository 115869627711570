import { useAccount } from '@core/components/Auth/AccountContext';
import TrackService, {
  TrackServiceEventProperties,
  TrackServiceNotifiableError,
} from './TrackService';
import { useCustomer } from '@core/components/App/CustomerContext';
import { MLError } from '..';

export interface TrackingProperties
  extends Pick<
    TrackServiceEventProperties,
    'feature' | 'error' | 'testProperties'
  > {
  name: string;
  [key: string]: unknown;
}

export interface ErrorEventTrackingProperties
  extends Pick<TrackServiceEventProperties, 'name' | 'feature'> {
  error: TrackServiceNotifiableError;
}

export interface EventTrackingProperties
  extends Pick<TrackingProperties, 'feature' | 'error'> {
  eventName: string;
  name?: TrackingProperties['name'];
  variant?: TrackingProperties['variant'];
}

export const useTracking = () => {
  const { accountId } = useAccount();
  const { id: customerId } = useCustomer();

  const user = {
    ecsCustomerId: customerId,
    ecsAccountId: accountId,
  };

  return {
    /** Tracks Page View event to Segment and FullStory */
    trackPage: ({ name, feature, error }: TrackingProperties) =>
      TrackService.trackPage(name, {
        feature,
        user,
        error,
      }),
    /** Tracks error event to Segment, FullStory and MLError */
    trackError: ({ name, feature, error }: ErrorEventTrackingProperties) => {
      TrackService.trackError(name, { feature, user, error });
      MLError.report({
        error,
        prefix: error.code,
        name,
      });
    },
    /** Tracks click event to Segment and FullStory */
    trackClick: ({
      name,
      feature,
      error,
      testProperties,
    }: TrackingProperties) =>
      TrackService.trackClick(name, { feature, user, error, testProperties }),
    /** Tracks generic event to Segment and FullStory */
    trackEvent: ({
      eventName,
      name,
      feature,
      variant,
      error,
    }: EventTrackingProperties) =>
      TrackService.trackEvent(eventName, {
        name,
        feature,
        variant,
        user,
        error,
      }),
  };
};
