import { Colors, H4, Icon, P3, Spacing } from '@missionlane/compass-ui';
import { useEffect, useState } from 'react';
import AndroidQRCode from '@core/assets/AndroidQR.svg';
import IosQRCode from '@core/assets/IosQR.svg';

import PlayStoreButtonSVG from '@core/assets/playStore.svg';
import AppStoreButtonSVG from '@core/assets/appStore.svg';
import { useLocalStorage } from '@core/utils/hooks/useLocalStorage';
import dayjs from 'dayjs';
import Modal from '../General/Modal/Modal';
import { useTracking } from '@core/services/TrackService/useTracking';
import { FEATURE_NAME } from './const';

type DeviceOS = 'iOS' | 'android' | undefined;

const downloadAppQRCodeStorageKey = 'dismissedDownloadQRCodeTileAt';

const DownloadMobileAppQRKard = () => {
  const { trackClick, trackEvent } = useTracking();

  // used for knowing which QR code to display based on the users click.
  const [selectedDeviceOS, setSelectedDeviceOs] = useState<DeviceOS>();
  // We start as true because this condition is used to hide a component when true.
  // We rather have the component pop into screen instead of popping out.
  const [hideDownloadAppQRCode, setHideDownloadAppQRCode] = useState(true);
  const agent = navigator.userAgent.toLowerCase();
  const { set, get } = useLocalStorage();
  const downloadAppQRCodeDismissedAt = get(downloadAppQRCodeStorageKey);
  const [dismissedDownloadAppTile, setDismissedDownloadAppTile] = useState(
    Boolean(downloadAppQRCodeDismissedAt),
  );
  const [isQRCodeModalOpen, setIsQRCodeModalOpen] = useState(false);
  const onCloseQRCodeModal = () => setIsQRCodeModalOpen(false);

  useEffect(() => {
    if (agent.match(/ios|iphone|android|ipad/)) {
      setHideDownloadAppQRCode(true);
    } else {
      setHideDownloadAppQRCode(false);
    }
  }, [agent]);
  const {} = useTracking();

  useEffect(() => {
    if (isQRCodeModalOpen)
      trackEvent({
        eventName: 'Modal Viewed',
        name: `${FEATURE_NAME.MY_PROGRESS_ONBOARDING}: ${selectedDeviceOS} QR code`,
        feature: FEATURE_NAME.MY_PROGRESS_ONBOARDING,
      });
  }, [isQRCodeModalOpen, selectedDeviceOS]);

  if (dismissedDownloadAppTile || hideDownloadAppQRCode) return null;

  return (
    <div
      className="flex flex-column relative"
      style={{
        borderRadius: Spacing.s,
        backgroundColor: Colors.hazeLightest,
        marginBottom: Spacing.xm,
      }}
    >
      <div style={{ margin: Spacing.xm }}>
        <div>
          <H4>More of an app person?</H4>
          <P3>
            Download the Mission Lane app for quicker sign in, alerts, and more
          </P3>
        </div>
        {/** QR and Buttons */}
        <div
          style={{ marginTop: Spacing.m }}
          className="flex flex-row justify-between"
        >
          <div className="flex flex-column">
            <OSDownloadButton
              setDeviceOS={() => {
                setSelectedDeviceOs('iOS');
                setIsQRCodeModalOpen(true);
                trackClick({
                  name: `${FEATURE_NAME.MY_PROGRESS_ONBOARDING}: ios QR code`,
                  feature: FEATURE_NAME.MY_PROGRESS_ONBOARDING,
                });
              }}
              deviceOS="iOS"
            />
            <OSDownloadButton
              setDeviceOS={() => {
                setSelectedDeviceOs('android');
                setIsQRCodeModalOpen(true);
                trackClick({
                  name: `${FEATURE_NAME.MY_PROGRESS_ONBOARDING}: android QR code`,
                  feature: FEATURE_NAME.MY_PROGRESS_ONBOARDING,
                });
              }}
              deviceOS="android"
              hasMarginTop
            />
          </div>
        </div>
      </div>
      {!dismissedDownloadAppTile && (
        <div
          className="pointer flex shadow-2"
          style={{
            position: 'absolute',
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: Spacing.xm,
            width: 25,
            height: 25,
            top: -Spacing.s,
            right: -Spacing.s,
            backgroundColor: Colors.white,
          }}
          onClick={() => {
            trackClick({
              name: `${FEATURE_NAME.MY_PROGRESS_ONBOARDING}: Download App QR Code Dismissed`,
              feature: FEATURE_NAME.MY_PROGRESS_ONBOARDING,
            });
            set(downloadAppQRCodeStorageKey, dayjs().toISOString());
            setDismissedDownloadAppTile(true);
          }}
        >
          <Icon name="close" color="ink" />
        </div>
      )}
      <Modal
        headerText={
          selectedDeviceOS === 'android' ? 'Google Play' : 'App Store'
        }
        onClose={onCloseQRCodeModal}
        maxWidth="640px"
        isOpen={isQRCodeModalOpen}
      >
        <QRCode deviceOS={selectedDeviceOS} />
      </Modal>
    </div>
  );
};

export default DownloadMobileAppQRKard;

interface QRCodeProps {
  deviceOS: DeviceOS;
}

interface OSDownloadButtonProps extends QRCodeProps {
  setDeviceOS: () => void;
  hasMarginTop?: boolean;
}

const QRCode = ({ deviceOS }: QRCodeProps) => {
  if (deviceOS) {
    return <img src={deviceOS === 'iOS' ? IosQRCode : AndroidQRCode} />;
  } else {
    return null;
  }
};

const OSDownloadButton = ({
  deviceOS,
  hasMarginTop,
  setDeviceOS,
}: OSDownloadButtonProps) => {
  return (
    <img
      style={{
        maxHeight: 56,
        marginTop: hasMarginTop ? Spacing.xm : undefined,
      }}
      onClick={setDeviceOS}
      src={deviceOS === 'iOS' ? AppStoreButtonSVG : PlayStoreButtonSVG}
    />
  );
};
