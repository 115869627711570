import ResponsiveTypography from '../General/ResponsiveTypography';

const ReferralsData = () => {
  return (
    <div className="mv4 flex flex-row justify-between">
      <div className="w-50 mr2 pa3 bg-haze-lightest br3">
        <ResponsiveTypography type="HEADER" mobileLevel="H4" desktopLevel="H5">
          Approved Referrals
        </ResponsiveTypography>
        <ResponsiveTypography //TODO: Add dynamic data
          type="HEADER"
          mobileLevel="H2"
          desktopLevel="H3"
        >
          2
        </ResponsiveTypography>
      </div>
      <div className="w-50 ml2 pa3 bg-haze-lightest br3">
        <ResponsiveTypography type="HEADER" mobileLevel="H4" desktopLevel="H5">
          Total Earned
        </ResponsiveTypography>
        <div className="flex flex-row items-center">
          <ResponsiveTypography //TODO: Add dynamic data
            type="HEADER"
            mobileLevel="H2"
            desktopLevel="H3"
          >
            $0
          </ResponsiveTypography>
          <ResponsiveTypography
            type="HEADER"
            mobileLevel="H3"
            desktopLevel="H4"
            color="inkLight"
          >
            /$300
          </ResponsiveTypography>
        </div>
      </div>
    </div>
  );
};

export default ReferralsData;
