import { ApolloClient, from, InMemoryCache } from '@apollo/client';
import { HttpLink, ServerParseError } from '@apollo/client/link/http';
import { setContext } from '@apollo/client/link/context';
import { ErrorResponse, onError } from '@apollo/client/link/error';
import { MLError, TrackService } from '@core/services';
import { GraphQLError } from 'graphql';
import LaunchDarklyClient from '@core/services/LaunchDarklyClient/LaunchDarklyClient';
import { HttpLinkParams } from './HttpLinkParams';

const httpLink = new HttpLink(HttpLinkParams);

const isServerParseError = (
  error: ErrorResponse['networkError'],
): error is ServerParseError => {
  return !!(error as ServerParseError).statusCode;
};

const errorLink = onError(({ graphQLErrors, networkError, operation }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach((error: GraphQLError) => {
      const notifiableError = MLError.report({
        error,
        name: 'Error',
        prefix: operation.operationName,
      });
      console.log(notifiableError.message);
    });
  }
  if (networkError) {
    if (isServerParseError(networkError)) {
      // Don't send 401s to MLError, but send them to Segment for analytics
      if (networkError.statusCode === 401) {
        const {
          bodyText,
          statusCode,
          name,
          response: { url },
        } = networkError;
        TrackService.track({
          event: 'Unauthorized Request',
          properties: {
            bodyText,
            statusCode,
            name,
            url,
          },
        });
        // Hard redirect to /signin to clear any local state
        window.location.href = '/signin';
        return;
      }
    }
    console.log(`[Network error]: ${networkError}`);

    if ('statusCode' in networkError && networkError.statusCode >= 500) {
      MLError.report({
        name: networkError.name,
        error: networkError,
      });
    }
  }
});

/*
  This header is used for fault injection testing.

  When the flag is enabled (not "off") it will pass the header and value to Apollo, which passes them downstream.
  If enabled, downstream services will fail if the if the value matches the service or namespace. 'all' causes all to fail.

  Example values:

  'service.namespace' (i.e. 'payments-service.payments')
  'namespace' (i.e. payments)
  'all'
*/
const mlFaultLink = setContext(async (operation, previousContext) => {
  await LaunchDarklyClient.waitUntilReady();

  const mlFaultTextHeader = LaunchDarklyClient.variation(
    'mlFaulttestHeader',
    'off',
  ) as string;

  if (mlFaultTextHeader === 'off' || !process.env.REACT_APP_ML_FAULTTEST) {
    return previousContext;
  }

  return {
    ...previousContext,
    headers: {
      ...previousContext.headers,
      'ml-faulttest': mlFaultTextHeader,
    },
  };
});

const typePolicies = {
  Account: {
    fields: {
      balanceInfo: { merge: true },
      paymentInfo: { merge: true },
      pricingDetails: { merge: true },
      statuses: { merge: true },
      addressChangeEligibility: { merge: true },
    },
  },
  Customer: {
    fields: {
      contactInfo: { merge: true },
      financialInfo: { merge: true },
    },
  },
  Card: {
    keyFields: ['last4'],
  },
  OfferAggregate: {
    keyFields: ['offerId'],
  },
  OnboardingProgress: {
    keyFields: ['accountId'],
  },
};

const apolloClient = new ApolloClient({
  link: from([errorLink, mlFaultLink, httpLink]),
  cache: new InMemoryCache({
    typePolicies,
    possibleTypes: {
      Activity: ['Payment', 'Transaction'],
    },
  }),
  connectToDevTools: process.env.NODE_ENV !== 'production',
});

export default apolloClient;
