import creditProtectionShield from '@core/assets/CreditProtectionShield.svg';
import {
  CLOSE_BUTTON_WIDTH,
  OpportunityTileCloseIcon,
  OpportunityTileContainer,
  OpportunityTileCTA,
  OpportunityTileDiagonalBackground,
} from '../AccountSummaryPage/Dashboard/OpportunityTileCarousel/OpportunityTiles/LayoutComponents';
import { useNavigate } from 'react-router-dom';
import { Link } from '@missionlane/compass-ui';
import { TrackService } from '@core/services';
import { CarouselTileProps } from '@core/components/Carousel/Carousel';
import { useEffect } from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { CreditProtectionTitleCopy } from './copy';
import { MLFlags } from 'flags';

const IMAGE_WIDTH = 80;
const IMAGE_RIGHT = 6;
const CONTENT_SAFE_MARGIN = IMAGE_WIDTH - CLOSE_BUTTON_WIDTH - IMAGE_RIGHT;
const IMAGE_BOTTOM = 6;
export const CREDIT_PROTECTION_TILE_EVENT =
  'Opportunity Tile: Credit Protection';

export const getCopy = (flagVariant: string) => {
  if (flagVariant === 'TILE_A' || flagVariant === 'MODAL_TILE_A') {
    return {
      title: CreditProtectionTitleCopy.TITLE_A,
      body: 'Enroll in Mission Lane Credit Protection to help ensure you’re covered.',
      cta: 'Learn More',
    };
  }

  if (flagVariant === 'TILE_B' || flagVariant === 'MODAL_TILE_B') {
    return {
      title: CreditProtectionTitleCopy.TITLE_B,
      body: 'Enroll in Mission Lane Credit Protection to help prevent missed payments.',
      cta: 'Learn More',
    };
  }

  if (flagVariant === 'TILE_C' || flagVariant === 'MODAL_TILE_C') {
    return {
      title: CreditProtectionTitleCopy.TITLE_C,
      body: 'Enroll in Mission Lane Credit Protection to help ensure you’re covered.',
      cta: 'Learn More',
    };
  }

  return {
    title: '',
    body: '',
    cta: '',
  };
};

const CreditProtectionTile = ({ onDismiss }: CarouselTileProps) => {
  const navigate = useNavigate();
  const { creditProtectionMarketing } = useFlags<MLFlags>();

  const { title, body } = getCopy(creditProtectionMarketing);

  useEffect(() => {
    if (creditProtectionMarketing === 'DISABLED') return;
    TrackService.trackEvent('Offer Viewed', {
      feature: CREDIT_PROTECTION_TILE_EVENT,
      variant: creditProtectionMarketing
    });
  }, [creditProtectionMarketing]);

  return (
    <OpportunityTileContainer backgroundColor="hazeLightest">
      <OpportunityTileDiagonalBackground
        background="hazeLightest"
        imgAlt="credit protection shield"
        imgSource={creditProtectionShield}
        imgWidth={IMAGE_WIDTH}
        imageRight={IMAGE_RIGHT}
        imageBottom={IMAGE_BOTTOM}
      />
      <OpportunityTileCTA
        trackingName={CREDIT_PROTECTION_TILE_EVENT}
        trackingProperties={{ variant: creditProtectionMarketing }}
        contentSafeMargin={CONTENT_SAFE_MARGIN}
        title={title}
        text={body}
        onButtonClick={() => navigate('../credit-protection')}
        buttonLabel="Learn More"
      />
      <Link
        onPress={() => onDismiss(CREDIT_PROTECTION_TILE_EVENT,
          { trackingProperties: { variant: creditProtectionMarketing } }
        )}
        style={{
          zIndex: 2,
        }}
      >
        <OpportunityTileCloseIcon />
      </Link>
    </OpportunityTileContainer>
  );
};

export default CreditProtectionTile;
