import { AccountBreadcrumbs } from '../Account/AccountBreadcrumbs';
import PageWrapper from '../Page/PageWrapper';
import { ContentCard } from '../General/ContentCard';
import ResponsiveTypography from '../General/ResponsiveTypography';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { MLFlags } from 'flags';
import { getCopy } from './CreditProtectionTile';
import { B, Button, Link, LoadingIndicator, P4 } from '@missionlane/compass-ui';
import Checkbox from '../General/Checkbox/Checkbox';
import { useNavigate } from 'react-router-dom';
import {
  GET_ACCOUNT_DETAILS_QUERY,
  GetAccountDetailsQuery,
} from '@clip/ClipProgress/network';
import { CreditProtectionEnrollmentChannel, GetAccountBreadcrumbsQueryVariables } from '@core/graphql/globalTypes';
import CreditProtectionFAQModal from './CreditProtectionFAQModal';
import { useTracking } from '@core/services/TrackService/useTracking';
import { createRef, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { ENROLL_IN_CREDIT_PROTECTION } from './enrollInCreditProtectionMutation';
import {
  EnrollInCreditProtectionMutationVariables,
  EnrollInCreditProtectionMutation,
} from '@core/graphql/globalTypes';
import { useAccount } from '../Auth/AccountContext';

const FEATURE_NAME = 'Credit Protection Enrollment Page';
const FAQ_MODAL_FEATURE_NAME = 'Credit Protection FAQ Modal';

const CreditProtectionPage = () => {
  const [isChecked, setIsChecked] = useState(false);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const { creditProtectionMarketing } = useFlags<MLFlags>();
  const { trackEvent, trackClick } = useTracking();
  const contentRef = createRef<HTMLDivElement>();
  const { accountId } = useAccount();

  const [enrollInCreditProtection, {
    loading: mutationLoading,
    error: mutationError,
  }] = useMutation<
    EnrollInCreditProtectionMutation,
    EnrollInCreditProtectionMutationVariables
  >(ENROLL_IN_CREDIT_PROTECTION);

  const { data, loading, error } = useQuery<
    GetAccountDetailsQuery,
    GetAccountBreadcrumbsQueryVariables
  >(GET_ACCOUNT_DETAILS_QUERY, {
    variables: { accountId },
    skip: !accountId,
  });

  const cardLast4 = data?.accountDetails?.cardLast4;

  const navigate = useNavigate();

  const onClose = () => {
    setIsOpen(false);
    trackEvent({
      eventName: `${FAQ_MODAL_FEATURE_NAME}: Close`,
      feature: FAQ_MODAL_FEATURE_NAME,
    });
  };

  const onOpen = () => {
    setIsOpen(true);
    trackEvent({
      eventName: `${FAQ_MODAL_FEATURE_NAME}: Open`,
      feature: FAQ_MODAL_FEATURE_NAME,
    });
  };

  const onCheckboxChange = () => {
    setIsChecked(!isChecked);
    trackEvent({
      eventName: `${FEATURE_NAME} checkbox open: ${isChecked}`,
      feature: FEATURE_NAME,
    });
  };

  const onCancelClicked = () => {
    trackClick({
      name: 'Cancel: Clicked',
      feature: FEATURE_NAME,
    });

    navigate('../');
  };

  const onSubmitClicked = async () => {
    if (!contentRef.current?.innerHTML) return; // unlikely to happen, just here to satisfy TS

    const result = await enrollInCreditProtection({
      variables: {
        snapshotContent: contentRef.current.innerHTML,
        enrollmentChannel: CreditProtectionEnrollmentChannel.Dash,
        accountId,
      },
    });

    if (mutationError || !result.data?.enrollInCreditProtection?.success) {
      trackEvent({
        eventName: `${FEATURE_NAME}: Error enrolling`,
        feature: FEATURE_NAME,
        error: mutationError,
      });

      return;
    }

    trackClick({
      name: 'Enroll in Credit Protection: Clicked',
      feature: FEATURE_NAME,
    });

    navigate('./success');
  };

  const title = getCopy(creditProtectionMarketing).title;

  if (loading) {
    return <LoadingIndicator />;
  }

  if (error || mutationError) throw error ?? mutationError;

  return (
    <>
      <AccountBreadcrumbs
        items={[{ text: 'Mission Lane Credit Protection' }]}
      />
      <div ref={contentRef}>
        <ContentCard>
          <ResponsiveTypography
            type="HEADER"
            mobileLevel="H2"
            desktopLevel="H3"
          >
            {title}
          </ResponsiveTypography>
          <div className="mv3">
            <ResponsiveTypography
              type="PARAGRAPH"
              mobileLevel="P1"
              desktopLevel="P3"
            >
              <B color="ink">Enrolling in Mission Lane Credit Protection</B> can
              help with your card account payments if you experience certain
              unexpected life events in the future.
            </ResponsiveTypography>
            <div>
              <ResponsiveTypography
                type="PARAGRAPH"
                mobileLevel="P1"
                desktopLevel="P3"
              >
                <ul>
                  <li>
                    Covered events include job loss, auto repairs,
                    hospitalization, and medical bills.
                  </li>
                  <li>
                    The fee for this service is{' '}
                    <B color="ink">
                      1.49% of your balance (shown as New Balance on your
                      monthly statement).
                    </B>
                  </li>
                  <li>
                    Coverage applies to the card you sign up with (account
                    ending in {cardLast4}).
                  </li>
                  <li>
                    Coverage requires enrollment and applies only to future
                    events.
                  </li>
                  <li>You can cancel at any time.</li>
                </ul>
              </ResponsiveTypography>
              <ResponsiveTypography
                type="PARAGRAPH"
                mobileLevel="P1"
                desktopLevel="P3"
              >
                <Link onPress={onOpen}>
                  Learn more about the limitations and details of Mission Lane
                  Credit Protection.
                </Link>
              </ResponsiveTypography>
              <div className="ba bw1 br2 mv3 b--washed-ink pa2">
                <iframe
                  src="https://cdn.missionlane.com/docs/cp-t-c"
                  width="100%"
                  style={{ border: 'none' }}
                />
              </div>
              <ResponsiveTypography
                type="PARAGRAPH"
                mobileLevel="P1"
                desktopLevel="P3"
              >
                <B color="ink">
                  Would you like to enroll in Credit Protection?
                </B>
              </ResponsiveTypography>
              <div className="ba bw1 br2 mv3 b--washed-ink pa3 flex flex-row">
                <Checkbox
                  id="CreditProtectionChecked"
                  name="CreditProtectionChecked"
                  onChange={onCheckboxChange}
                />
                <div className="pl3">
                  <ResponsiveTypography
                    type="PARAGRAPH"
                    mobileLevel="P1"
                    desktopLevel="P3"
                    color="ink"
                  >
                    Yes, protect my card account ending in {cardLast4}.
                  </ResponsiveTypography>
                  <P4>
                    I have read, understand and agree to the Terms and
                    Conditions of Credit Protection.
                  </P4>
                </div>
              </div>
              <div className="flex flex-row">
                <div className="mr2">
                  <Button
                    variant="contained"
                    text="Enroll in Credit Protection"
                    onPress={onSubmitClicked}
                    loading={mutationLoading}
                    disabled={!isChecked}
                  />
                </div>
                <Button
                  variant="text"
                  text="Cancel"
                  onPress={onCancelClicked}
                />
              </div>
            </div>
          </div>
        </ContentCard>
      </div>
      <CreditProtectionFAQModal isOpen={isOpen} onClose={onClose} />
    </>
  );
};

const WrappedCreditProtectionPage = () => (
  <PageWrapper
    pageTitle={{ primaryText: 'Mission Lane Credit Protection' }}
    trackingProperties={{ pageName: FEATURE_NAME }}
    greyBackground
  >
    <CreditProtectionPage />
  </PageWrapper>
)

export default WrappedCreditProtectionPage
