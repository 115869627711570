import { AccountBreadcrumbs } from '../Account/AccountBreadcrumbs';
import { ContentCard } from '../General/ContentCard';
import PageWrapper from '../Page/PageWrapper';
import ResponsiveTypography from '../General/ResponsiveTypography';
import { Button, Link } from '@missionlane/compass-ui';
import { useNavigate } from 'react-router-dom';

const CreditProtectionIneligiblePage = () => {
  const navigate = useNavigate();

  const onClickReturnHome = () => {
    navigate('../summary');
  };

  const onClickContactLink = () => {
    window.open(process.env.REACT_APP_ZENDESK_URL, '_blank');
  };

  return (
    <>
      <AccountBreadcrumbs
        items={[{ text: 'Mission Lane Credit Protection' }]}
      />
      <ContentCard>
        <ResponsiveTypography type="HEADER" mobileLevel="H2" desktopLevel="H3">
          You’re ineligible to enroll in Mission Lane Credit Protection right
          now
        </ResponsiveTypography>
        <div className="mt3 mb4">
          <ResponsiveTypography
            type="PARAGRAPH"
            mobileLevel="P1"
            desktopLevel="P3"
          >
            We encourage you to check back later. If you have any questions,{' '}
            <Link onPress={onClickContactLink}>please reach out.</Link>
          </ResponsiveTypography>
        </div>
        <div className="w-50-l">
          <Button
            variant="contained"
            text="Return Home"
            onPress={onClickReturnHome}
            size="small"
          />
        </div>
      </ContentCard>
    </>
  );
};

const WrappedCreditProtectionPage = () => (
  <PageWrapper
    pageTitle={{ primaryText: 'Mission Lane Credit Protection' }}
    trackingProperties={{ pageName: 'Credit Protection Ineligible' }}
    greyBackground
  >
    <CreditProtectionIneligiblePage />
  </PageWrapper>
);

export default WrappedCreditProtectionPage;
