import { useEffect, useState } from 'react';
import { gql } from '@apollo/client';
import { useAccountIdQuery } from '@core/utils/hooks/useAccountIdQuery';
import { centsToDollars } from '@core/utils/centsToDollars';
import { B, Colors, H3, Icon, P3, P4, Spacing } from '@missionlane/compass-ui';
import Kard from '../General/Kard/Kard';
import LoadingSpinner from '../General/LoadingSpinner';
import Modal from '../General/Modal/Modal';

import type {
  GetCreditLimitQuery,
  GetCreditLimitQueryVariables,
} from '@core/graphql/globalTypes';
import { useTracking } from '@core/services/TrackService/useTracking';
import { FEATURE_NAME } from '../MyProgressOnboarding/const';

export const CREDIT_LIMIT_COPY = {
  tooltipText: 'Credit Limit',
  title: 'What does this mean?',
  descriptionA:
    'Your credit limit is the dollar amount of credit available to borrow on your account.',
  descriptionB:
    'Your account is automatically evaluated for credit limit increases on a regular basis. To be eligible for an increase, use your card, make payments on time, and keep your account in good standing.',
  advantagesTitle:
    'A higher credit limit can help progress your financial journey in many ways, including:',
  advantagesBulletA:
    'More flexibility for larger purchases or unplanned expenses',
  advantagesBulletB: 'More opportunities to build credit with responsible use',
  confirmText: 'Got it',
} as const;

export const getCreditLimitQuery = gql`
  query GetCreditLimit($accountId: String!) {
    account(accountId: $accountId) {
      creditLimit
    }
  }
`;

const CreditLimitKard = () => {
  const { trackEvent, trackClick } = useTracking();
  const [isCreditSheetOpen, setIsCreditSheetOpen] = useState(false);
  const { data, error, loading } = useAccountIdQuery<
    GetCreditLimitQuery,
    GetCreditLimitQueryVariables
  >(getCreditLimitQuery);

  useEffect(() => {
    if (isCreditSheetOpen)
      trackEvent({
        eventName: 'Modal Viewed',
        name: `${FEATURE_NAME.MY_PROGRESS_ONBOARDING}: Credit Limit`,
        feature: FEATURE_NAME.MY_PROGRESS_ONBOARDING,
      });
  }, [isCreditSheetOpen]);

  const onOpenCreditSheet = () => {
    trackClick({
      name: `${FEATURE_NAME.MY_PROGRESS_ONBOARDING}: Credit Limit Info`,
      feature: FEATURE_NAME.MY_PROGRESS_ONBOARDING,
    });
    setIsCreditSheetOpen(true);
  };

  const onCloseCreditSheet = () => setIsCreditSheetOpen(false);

  if (error) return null;

  return (
    <Kard testID="Credit Limit" className="mb3">
      {loading ?
        <LoadingSpinner />
      : <>
          {data?.account?.creditLimit && (
            <H3>{centsToDollars(data?.account?.creditLimit)}</H3>
          )}
          <div className="flex flex-row items-center">
            <div className="pointer mr1" onClick={onOpenCreditSheet}>
              <Icon name="infoCircleSolid" color="blue" size={16} />
            </div>
            <P4 style={{ marginVertical: 0 }}>Credit Limit</P4>
          </div>
        </>
      }

      <Modal
        headerText="Credit Limit"
        onClose={onCloseCreditSheet}
        maxWidth="640px"
        isOpen={isCreditSheetOpen}
      >
        <div className="flex flex-column">
          <P3 style={{ marginVertical: 0 }} color="ink">
            <B>{CREDIT_LIMIT_COPY.title}</B>
          </P3>
          <P3>{CREDIT_LIMIT_COPY.descriptionA}</P3>
          <P3>{CREDIT_LIMIT_COPY.descriptionB}</P3>
          <P3>{CREDIT_LIMIT_COPY.advantagesTitle}</P3>
          <ul>
            <BulletPointWithText
              copy={CREDIT_LIMIT_COPY.advantagesBulletA}
              hasBottomMargin
            />
            <BulletPointWithText copy={CREDIT_LIMIT_COPY.advantagesBulletB} />
          </ul>
        </div>
      </Modal>
    </Kard>
  );
};

export default CreditLimitKard;

interface BulletPointWithTextProps {
  copy: string;
  hasBottomMargin?: boolean;
}

const BulletPointWithText = ({
  copy,
  hasBottomMargin,
}: BulletPointWithTextProps) => {
  return (
    <li
      style={{
        color: Colors.inkLight,
        marginBottom: hasBottomMargin ? Spacing.m : undefined,
      }}
    >
      <P3
        style={{
          marginVertical: 0,
          lineHeight: 0,
        }}
      >
        {copy}
      </P3>
    </li>
  );
};
