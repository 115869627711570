import { Button, Link } from '@missionlane/compass-ui';
import { AccountBreadcrumbs } from '../Account/AccountBreadcrumbs';
import { ContentCard } from '../General/ContentCard';
import ResponsiveTypography from '../General/ResponsiveTypography';
import PageWrapper from '../Page/PageWrapper';
import referralsHeader from '@core/assets/referralsHeader.svg';
import ReferralsData from './ReferralsData';
import ReferralsInstructions from './ReferralsInstructions';
import './Referrals.css';
import { useState } from 'react';

const FEATURE_NAME = 'Referrals Page';

const ReferralsPage = () => {
  const [showCopiedMessage, setShowCopiedMessage] = useState(false);

  const handleCopyLink = () => {
    navigator.clipboard.writeText(process.env.REACT_APP_REFERRALS_URL || '');

    // Show "Link Copied" message
    setShowCopiedMessage(true);

    // Hide the message after 2 seconds
    setTimeout(() => {
      setShowCopiedMessage(false);
    }, 2000);
  };

  return (
    <>
      <AccountBreadcrumbs items={[{ text: 'Refer and Earn' }]} />
      <ContentCard>
        <img src={referralsHeader} className="w-100" />
        <div className="mv4">
          <ResponsiveTypography
            type="PARAGRAPH"
            mobileLevel="P1"
            desktopLevel="P3"
          >
            Refer a friend and get a $[z] statement credit if they open a
            Mission Lane Visa® Credit Card Account. You can earn up to $[x]!{' '}
            <Link onPress={() => console.log('TODO T&C')}>Terms apply.</Link>
          </ResponsiveTypography>
        </div>

        <div className="ba flex flex-row justify-between items-center pa2 ba b--blue bw1 br2 relative">
          {showCopiedMessage && (
            <div className="copied-popup">
              <ResponsiveTypography
                type="PARAGRAPH"
                mobileLevel="P1"
                desktopLevel="P3"
                color="white"
                className="flex items-center justify-center bg-pine br2"
              >
                Link Copied!
              </ResponsiveTypography>
            </div>
          )}
          <ResponsiveTypography
            type="PARAGRAPH"
            mobileLevel="P1"
            desktopLevel="P3"
            color="ink"
            className="w-70-ns w-100"
          >
            <div className="link-container" onClick={handleCopyLink}>
              {process.env.REACT_APP_REFERRALS_URL}
            </div>
          </ResponsiveTypography>
          <div className="flex-ns dn">
            <Button
              variant="contained"
              text="Copy link"
              size="standard"
              onPress={handleCopyLink}
            />
          </div>
        </div>
        <div className="dn-ns pt3 items-center justify-center">
          <Button
            variant="contained"
            text="Copy link"
            size="standard"
            onPress={handleCopyLink}
          />
        </div>
        <ReferralsData />
        <ReferralsInstructions />
        <div className="mt4">
          <ResponsiveTypography
            type="PARAGRAPH"
            mobileLevel="P1"
            desktopLevel="P3"
          >
            This offer is valid until Dec, 31, 2024.
          </ResponsiveTypography>
        </div>
      </ContentCard>
    </>
  );
};

const WrappedRefferalsPage = () => (
  <PageWrapper
    pageTitle={{ primaryText: 'Refer and Earn' }}
    trackingProperties={{ pageName: FEATURE_NAME }}
    greyBackground
  >
    <ReferralsPage />
  </PageWrapper>
);

export default WrappedRefferalsPage;
