import { useNavigate } from 'react-router-dom';
import { MenuLabel } from './MenuLabel';
import { MenuItem } from './MenuItem';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { MLFlags } from 'flags';

export const PersonalSection = () => {
  const navigate = useNavigate();
  const { showReferrals } = useFlags<MLFlags>();
  return (
    <>
      <MenuLabel label="Personal" />
      <MenuItem
        trackingName="Profile drop down: My profile"
        onSelect={() => navigate('/my-profile')}
        icon="user"
      >
        My profile
      </MenuItem>
      {showReferrals && (
        <MenuItem
          trackingName="Profile drop down: My profile"
          onSelect={() => navigate('../referrals')}
          icon="bullhorn"
        >
          Refer a friend
        </MenuItem>
      )}
    </>
  );
};
