import { useFlags } from 'launchdarkly-react-client-sdk';
import LoadingSpinner from '@core/components/General/LoadingSpinner';
import { useAccountIdQuery } from '@core/utils/hooks/useAccountIdQuery';
import { useContext, useEffect } from 'react';
import { AccountInformationContext } from '@core/components/AccountInformation/AccountInformationProvider';
import { useCustomerAndAccountIdentifiers } from '@core/utils/hooks/useCustomerAndAccountIdentifiers';
import PaymentKard from '@payments/components/PaymentKard/PaymentKard';
import RecentActivityKard from '@core/components/RecentActivityKard/RecentActivityKard';
import { CashFlowUnderwritingExperience } from '@clip/CashFlowUnderwritingExperience/kards';
import CreditScore from '@core/components/CreditScore/CreditScore';
import FinancialLiteracyKard from '@core/components/FinancialLiteracyKard/FinancialLiteracyKard';
import ContactHelpKard from '@core/components/ContactHelpKard/ContactHelpKard';
import { usePaymentStatus } from '@payments/hooks/usePaymentStatus';
import { DefaultDashboardQuery } from '@core/graphql/globalTypes';
import { SpendBonus } from '@clip/SpendBonus';
import { IncomeUpdateBanner } from '@clip/IncomeUpdate';
import { GoodStandingTips } from '@clip/GoodStandingTips';
import { gql } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import { useTracking } from '@core/services/TrackService/useTracking';
import { MLFlags } from 'flags';
import OnboardingAutoPayKard from './OnboardingAutoPayKard/OnboardingAutoPayKard';
import ClipOffer from '@clip/ClipProgress/kards/ClipOffer/ClipOffer';
import { AccountSummaryCard } from '@core/components/AccountSummaryCard/AccountSummaryCard';
import DynamicAccountSummaryKard from '@core/components/DynamicAccountSummaryKard/DynamicAccountSummaryKard';
import OpportunityTileCarousel from './OpportunityTileCarousel/OpportunityTileCarousel';

export const DEFAULT_DASHBOARD_QUERY = gql`
  query DefaultDashboard($accountId: String!) {
    account(accountId: $accountId) {
      id
      statuses {
        isBankrupt
      }
    }
    accountDetails(accountId: $accountId) {
      id
      name
      cardLast4
      cardArtUrl
      securedCardInfo {
        currentAmount {
          value
        }
      }
      cardLockStatus {
        isLocked
      }
    }
  }
`;

const DefaultDashboard = () => {
  const navigate = useNavigate();
  const { trackClick } = useTracking();
  const {
    showCreditScore,
    enableAccountSummaryCardArt,
    enableMyProgressOnboarding,
  } = useFlags<MLFlags>();

  const { data, loading } = useAccountIdQuery<DefaultDashboardQuery>(
    DEFAULT_DASHBOARD_QUERY,
    {
      errorPolicy: 'all',
    },
  );

  const {
    refreshEnabled,
    refresh,
    accountInformation,
    loading: acctInfoLoading,
    rtfTreatment,
  } = useContext(AccountInformationContext);

  const customerAndAccountIds = useCustomerAndAccountIdentifiers();

  const {
    isPastDue,
    showPastDueCredit,
    showSettledCurrentBalance,
    hasPastDueBalancePayment,
    isChargedOff,
  } = usePaymentStatus();

  useEffect(() => {
    if (refreshEnabled && !!refresh) {
      refresh({
        refetchInterval: 2000,
        refetches: 5,
      });
    }
  }, [refreshEnabled, refresh]);

  /**
   * When the card is locked, a lock icon button is displayed to
   *  navigate the user to the Card Lock feature
   */
  const onClickLock = () => {
    trackClick({
      name: 'Account Summary Card: Lock icon',
      feature: 'Account Summary',
    });
    navigate('../manage-card/lock-card');
  };

  if (loading || acctInfoLoading) return <LoadingSpinner />;

  const { accountDetails } = data || {};
  const isBankrupt = data?.account?.statuses?.isBankrupt;
  const { name, cardLast4, securedCardInfo, cardLockStatus, cardArtUrl } =
    accountDetails || {};
  const { availableCredit, currentBalance } = accountInformation || {};
  const canDisplayDynamicCard = enableAccountSummaryCardArt && cardArtUrl;

  return (
    <div data-testid="DefaultDashboard">
      <IncomeUpdateBanner />
      <div className="flex ph0-l">
        <div className="w-100 w-50-l">
          {canDisplayDynamicCard ?
            <DynamicAccountSummaryKard
              cardArtUrl={cardArtUrl}
              last4={cardLast4}
              currentBalance={currentBalance}
              availableCredit={availableCredit}
              cardName={name}
              onUnlock={onClickLock}
              isLocked={Boolean(cardLockStatus?.isLocked)}
              securityDeposit={securedCardInfo?.currentAmount?.value}
              showSettledCurrentBalance={showSettledCurrentBalance}
              showPastDueCredit={showPastDueCredit}
            />
          : <div className="pb3">
              <AccountSummaryCard
                isChargedOff={isChargedOff}
                showSettledCurrentBalance={showSettledCurrentBalance}
                showPastDueCredit={showPastDueCredit}
                headerLevel="H4"
                availableCredit={availableCredit}
                cardName={name}
                cardLast4={cardLast4}
                currentBalance={currentBalance}
                securityDeposit={securedCardInfo?.currentAmount?.value}
                cardAction={{
                  label: 'Account details',
                  linkTo: '../details',
                  tracking: {
                    trackingName: 'Account Summary: Account details link',
                    trackingProperties: customerAndAccountIds,
                  },
                }}
                rtfTreatment={rtfTreatment}
                isPastDue={isPastDue}
                isLocked={Boolean(cardLockStatus?.isLocked)}
                onUnlock={onClickLock}
                hasPastDueBalancePayment={hasPastDueBalancePayment}
              />
            </div>
          }
          <div className="db dn-l pb3">
            <PaymentKard />
          </div>
          {!isBankrupt && (
            <div className="db dn-l">
              <OpportunityTileCarousel />
            </div>
          )}
          <div className="pb3">
            <RecentActivityKard />
            <CashFlowUnderwritingExperience />
          </div>
          {!enableMyProgressOnboarding && (
            <>
              <div className="db dn-l">
                <SpendBonus />
                <ClipOffer />
              </div>
              <div className="db dn-l">
                <GoodStandingTips />
              </div>
            </>
          )}
          <div className="db dn-l pb3">
            <OnboardingAutoPayKard onDefaultDashboard />
          </div>
          {showCreditScore && !enableMyProgressOnboarding && (
            <div className="db dn-l">
              <CreditScore className="mb3" />
            </div>
          )}
          <div className="db dn-l">
            <FinancialLiteracyKard />
            <ContactHelpKard />
          </div>
        </div>
        <div className="dn db-l w-50 pl3">
          <div className="pb3">
            <PaymentKard />
          </div>
          <OnboardingAutoPayKard onDefaultDashboard />
          <div>
            {!isBankrupt && <OpportunityTileCarousel />}
            {!enableMyProgressOnboarding && (
              <>
                <SpendBonus />
                <ClipOffer />
              </>
            )}
          </div>
          {!enableMyProgressOnboarding && (
            <div>
              <GoodStandingTips />
            </div>
          )}
          {showCreditScore && !enableMyProgressOnboarding && (
            <div className="pb3">
              <CreditScore className="mb3" />
            </div>
          )}
          <div>
            <FinancialLiteracyKard />
            <ContactHelpKard />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DefaultDashboard;
