import { ReactNode } from 'react';
import ResponsiveTypography from '../General/ResponsiveTypography';
import './Referrals.css';
import { Link } from '@missionlane/compass-ui';

type ReferralStep = {
  stepNum: string;
  title: string;
  body: ReactNode;
};

const referralSteps: ReferralStep[] = [
  {
    stepNum: '1',
    title: 'Share your code',
    body: (
      <ResponsiveTypography type="PARAGRAPH" mobileLevel="P1" desktopLevel="P3">
        Either copy-and-paste your personal referral link and share it via text
        or on social media, or tap the big blue button.
      </ResponsiveTypography>
    ),
  },
  {
    stepNum: '2',
    title: 'Your friend gets a Mission Lane credit card',
    body: (
      <ResponsiveTypography type="PARAGRAPH" mobileLevel="P1" desktopLevel="P3">
        Your friend must follow the link you shared when they fill out the
        Mission Lane application. If they do AND they are approved...
      </ResponsiveTypography>
    ),
  },
  {
    stepNum: '3',
    title: 'You receive a statement credit',
    body: (
      <ResponsiveTypography type="PARAGRAPH" mobileLevel="P1" desktopLevel="P3">
        Your $10 statement credit will show up in your account within [X days]!
        You can track your total earned in the Mission Lane Mobile App. Terms
        apply. See{' '}
        <Link
          onPress={() => {
            console.log('TODO');
          }}
        >
          Mission Lane Referral Bonus Terms
        </Link>{' '}
        for details.
      </ResponsiveTypography>
    ),
  },
];

const StepItem = ({ stepNum, title, body }: ReferralStep) => {
  return (
    <div className="flex flex-row mt4">
      <div className="step-number mr4">
        <ResponsiveTypography type="HEADER" mobileLevel="H3" desktopLevel="H4">
          {stepNum}
        </ResponsiveTypography>
      </div>
      <div className="flex flex-column">
        <ResponsiveTypography type="HEADER" mobileLevel="H4" desktopLevel="H5">
          {title}
        </ResponsiveTypography>
        {body}
      </div>
    </div>
  );
};

const ReferralsInstructions = () => {
  return (
    <div>
      <ResponsiveTypography type="HEADER" mobileLevel="H3" desktopLevel="H4">
        Here’s how it works:
      </ResponsiveTypography>
      <div>
        {referralSteps.map((step, i) => {
          return <StepItem key={i} {...step} />;
        })}
      </div>
    </div>
  );
};

export default ReferralsInstructions;
