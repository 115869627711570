import { AccountBreadcrumbs } from '../Account/AccountBreadcrumbs';
import { ContentCard } from '../General/ContentCard';
import ResponsiveTypography from '../General/ResponsiveTypography';
import PageWrapper from '../Page/PageWrapper';
import { Button, Link, LoadingIndicator } from '@missionlane/compass-ui';
import GenericFallbackFull from '../GenericFallbacks/GenericFallbackFull';

import creditProtectionShieldSuccess from '@core/assets/CreditProtectionShieldSuccess.svg';
import { useNavigate } from 'react-router-dom';
import { useAccountIdQuery } from '@core/utils/hooks/useAccountIdQuery';
import {
  GET_ACCOUNT_DETAILS_QUERY,
  GetAccountDetailsQuery,
} from '@clip/ClipProgress/network';
import { GetAccountBreadcrumbsQueryVariables } from '@core/graphql/globalTypes';

const CreditProtectionSuccessPage = () => {
  const navigate = useNavigate();
  const { data, loading, error } = useAccountIdQuery<
    GetAccountDetailsQuery,
    GetAccountBreadcrumbsQueryVariables
  >(GET_ACCOUNT_DETAILS_QUERY);

  const cardLast4 = data?.accountDetails?.cardLast4;

  if (loading) {
    return <LoadingIndicator />;
  }

  if (error) {
    return <GenericFallbackFull />;
  }

  return (
    <PageWrapper
      pageTitle={{ primaryText: 'Mission Lane Credit Protection' }}
      greyBackground
      trackingProperties={{
        featureName: 'Credit Protection',
        pageName: 'Credit Protection Success',
      }}
    >
      <AccountBreadcrumbs
        items={[{ text: 'Mission Lane Credit Protection' }]}
      />
      <ContentCard>
        <ResponsiveTypography type="HEADER" mobileLevel="H2" desktopLevel="H3">
          Your card ending in {cardLast4} is now covered by Mission Lane Credit
          Protection!
        </ResponsiveTypography>
        <div
          className="flex items-center justify-center br2 mb4 mt4"
          style={{ height: '184px' }}
        >
          <img className="h-75" src={creditProtectionShieldSuccess} />
        </div>
        <ResponsiveTypography
          type="PARAGRAPH"
          mobileLevel="P1"
          desktopLevel="P3"
        >
          For more details or to manage your enrollment, see your{' '}
          <Link onPress={() => navigate('../../details')}>
            Account Details.
          </Link>
        </ResponsiveTypography>
        <div className="flex mt4 mb2">
          <Button
            variant="contained"
            text="Go Back Home"
            onPress={() => navigate('../..')}
            size="small"
          />
        </div>
      </ContentCard>
    </PageWrapper>
  );
};

export default CreditProtectionSuccessPage;
